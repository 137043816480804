<script setup>
import ProductsGrid from "@/components/molecules/ProductsGrid.vue";
import ProductsCarousel from "~/components/organisms/ProductsCarousel.vue";
import Heading from "~/components/atoms/Heading.vue";

const props = defineProps({
  data: Object,
})

const {products} = toRefs(props.data)
</script>

<template>
  <div class="g-24">
    <Heading v-if="props.data.title" tag="h2">{{ props.data.title }}</Heading>
    <ProductsCarousel v-if="props.data.type === 'carousel'" :products="products.map(product => ({
  ...product,
  bold_name: product.display_name,
  images: {
    image: product.image,
    second_image: product.second_image
  },
  prices: {
    standard: product.main_price ?? product.hc_standard_price,
    recommended: product.hc_recommended_price,
    discount: product.discount
  },
  }))"/>
    <ProductsGrid v-else :no-header="true" :products="products"/>
  </div>
</template>
